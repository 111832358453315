<template>
    <div>
        <a-row>
            <a-col :span="24">
                <a-form-model  layout="inline" :model="form" >
                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
                        <a-input v-model="form.serial_code" placeholder="请输入执行标准"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
                        <a-input v-model="form.name" placeholder="请输文档名称"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
                        <a-button @click="Clear()" type="primary">清空</a-button>
                    </a-form-model-item>
                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
                        <a-button @click="showDrawer({
            id:0,
            name:'',
            serial_code:'',
            xize_code:'',
            file_url:'',
            file_name:'',
            })" type="primary">新建检测标准文档
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
                </a-col>
        </a-row>


        <table class="layui-table">
            <thead>
            <tr>
                <th>执行标准</th>
                <th>名称</th>
                <th>细则编号</th>
                <th>文件</th>
                <th>操作</th>
            </tr>
            </thead>
            <tr v-for="(item,key) in document_list">

                <td>{{ item.serial_code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.xize_code }}</td>
                <td>
                    <a-space>
                        {{ item.file_name }}<a href="item.file_url"><a-icon type="download" style="color: #1890ff"/></a>
                    </a-space>
                </td>
                <td>
                    <a-space>
                        <a-button type="primary" @click="showDrawer(item)">编辑</a-button>
                        <a-button type="primary" @click="showDrawer2(item)">历史记录</a-button>
                        <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                            <template slot="title">确认删除该项？</template>
                            <a-button type="danger" class="ml20">删除</a-button>
                        </a-popconfirm>
                    </a-space>
                </td>
            </tr>
        </table>

        <a-pagination :current="0" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>


        <!--抽屉-->
        <div v-if="edit_info">
            <labopen1 :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible1"></labopen1>
        </div>
       <div v-if="history_item">
           <labopen2 :history_time="history_time" :history_item="history_item" :close="onCloseDrawer2" :visible="drawer_visible2"></labopen2>
       </div>
    </div>
</template>

<script>
    import labopen1 from "../../../components/drawer/jiancebiaozhunwendang-open1/jiancebiaozhunwendang-open1.vue";
    import labopen2 from "../../../components/drawer/jiancebiaozhunwendang-open2/jiancebiaozhunwendang-open2.vue";

    export default {
        components: {
            labopen1,
            labopen2,
        },
        data() {
            return {
                form:{
                    serial_code: '',
                    name: '',
                },
                page: 1,
                edit_time: 0,
                count: 0,
                pagesize: 0,
                document_list: [],
                // 定义控制抽屉显示的变量
                edit_info: false,
                drawer_visible1: false,
                drawer_visible2: false,
                history_item: false,
                history_time: 0,

                page_show:false,
            };
        },
        watch: {
            form: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },

            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            mountedDo(){
                this.page = this.$route.query.page || 1;
                this.form.serial_code = this.$route.query.serial_code || '';
                this.form.name = this.$route.query.name || '';
                this.Get_document()//获取所有文档列表
                setTimeout(() => {
                    this.page_show = true
                }, 100)
            },

            //删除
            delClick(item) {
                //console.log('delClick-----------------')
                //console.log('url-----------------',this.$api('Del_document'))
                this.$sa0.post({
                    url: this.$api('Del_document'),
                    data: {
                        id: item.id,
                    }
                }).then((response) => {
                    //console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_document()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 搜索
            searchClick() {
                this.page = 1;
                this.$router.push({
                    query: {
                        id: this.id,
                        serial_code: this.form.serial_code,
                        name: this.form.name,
                        page: 1,
                    }
                })
            },
            Clear() {
                this.form.serial_code = '';
                this.form.name = '';
                this.page = 1;
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        serial_code: this.form.serial_code,
                        name: this.form.name,
                    }
                })
            },

            //获取所有文档列表
            Get_document() {
                this.$sa0.post({
                    url: this.$api('Get_document'),
                    data: {
                        page: this.page,
                        serial_code: this.form.serial_code,
                        name: this.form.name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.document_list = response.data.result.list;
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 打开抽屉时的 函数 编辑
            showDrawer(item) {
                this.edit_time = new Date() / 1;
                this.edit_info = item
                this.drawer_visible1 = true;
            },
            // 定义 关闭抽屉时的 函数 编辑
            onCloseDrawer() {
                this.Get_document()
                this.drawer_visible1 = false;
            },

            // 定义 打开抽屉时的 函数 历史记录
            showDrawer2(item) {
                this.history_item = item
                this.history_time = new Date / 1;
                this.drawer_visible2 = true;
                //this.$store.state.history_item = item;

            },
            // 定义 关闭抽屉时的 函数 历史记录
            onCloseDrawer2() {
                this.drawer_visible2 = false;
            },


        },
    };
</script>

<style scoped>
    .layui-table th{ text-align: left}
    .createsmapletit {
        font-size: 20px;
        font-weight: bold
    }

    .top {
        padding: 15px 0
    }

    .top input {
        width: 220px
    }

    .top span, .top button, .top input {
        margin-left: 15px
    }


</style>
