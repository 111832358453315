var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-model',{attrs:{"layout":"inline","model":_vm.form}},[_c('a-form-model-item',{attrs:{"label":"","label-col":{ span: 0, offset: 0 },"wrapper-col":{ span: 24, offset: 0 }}},[_c('a-input',{attrs:{"placeholder":"请输入执行标准"},model:{value:(_vm.form.serial_code),callback:function ($$v) {_vm.$set(_vm.form, "serial_code", $$v)},expression:"form.serial_code"}})],1),_c('a-form-model-item',{attrs:{"label":"","label-col":{ span: 0, offset: 0 },"wrapper-col":{ span: 24, offset: 0 }}},[_c('a-input',{attrs:{"placeholder":"请输文档名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"","label-col":{ span: 0, offset: 0 },"wrapper-col":{ span: 24, offset: 0 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.Clear()}}},[_vm._v("清空")])],1),_c('a-form-model-item',{attrs:{"label":"","label-col":{ span: 0, offset: 0 },"wrapper-col":{ span: 24, offset: 0 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showDrawer({
        id:0,
        name:'',
        serial_code:'',
        xize_code:'',
        file_url:'',
        file_name:'',
        })}}},[_vm._v("新建检测标准文档 ")])],1)],1)],1)],1),_c('table',{staticClass:"layui-table"},[_vm._m(0),_vm._l((_vm.document_list),function(item,key){return _c('tr',[_c('td',[_vm._v(_vm._s(item.serial_code))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.xize_code))]),_c('td',[_c('a-space',[_vm._v(" "+_vm._s(item.file_name)),_c('a',{attrs:{"href":"item.file_url"}},[_c('a-icon',{staticStyle:{"color":"#1890ff"},attrs:{"type":"download"}})],1)])],1),_c('td',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showDrawer(item)}}},[_vm._v("编辑")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showDrawer2(item)}}},[_vm._v("历史记录")]),_c('a-popconfirm',{attrs:{"placement":"top","ok-text":"确定","cancel-text":"取消","okType":"danger"},on:{"confirm":function($event){return _vm.delClick(item)}}},[_c('template',{slot:"title"},[_vm._v("确认删除该项？")]),_c('a-button',{staticClass:"ml20",attrs:{"type":"danger"}},[_vm._v("删除")])],2)],1)],1)])})],2),(_vm.count>_vm.pagesize)?_c('a-pagination',{attrs:{"current":0,"pageSize":_vm.pagesize,"total":_vm.count},on:{"change":_vm.onChange}}):_vm._e(),(_vm.edit_info)?_c('div',[_c('labopen1',{attrs:{"edit_time":_vm.edit_time,"edit_info":_vm.edit_info,"close":_vm.onCloseDrawer,"visible":_vm.drawer_visible1}})],1):_vm._e(),(_vm.history_item)?_c('div',[_c('labopen2',{attrs:{"history_time":_vm.history_time,"history_item":_vm.history_item,"close":_vm.onCloseDrawer2,"visible":_vm.drawer_visible2}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("执行标准")]),_c('th',[_vm._v("名称")]),_c('th',[_vm._v("细则编号")]),_c('th',[_vm._v("文件")]),_c('th',[_vm._v("操作")])])])
}]

export { render, staticRenderFns }